.App-routes-container {
	margin-top: 20px
}

.container {
  max-width: 960px;
}

a {
  color: var(--bs-primary);
  text-decoration: none;
}

a:hover {
  color: var(--bs-primary);
}
