.Cookie-group-row {
    margin: 0 0 20px 0;
}

.Cookie-card {
    border-style: none;
    padding-top: 20px;
    border-radius: var(--bs-card-border-radius);

    // Reduce layout shift when loading images for the first time
    img {
        min-height: 245px;
        min-width: 245px;
    }
}

.Cookie-card-border {
    border: var(--bs-card-border-width) solid;
    border-radius: var(--bs-card-border-radius);
}

.Cookie-card-body {
    text-align: center;
    background-color: var(--bs-primary);
}

.Cookie-card-body .card-title {
    color: var(--bs-light);
}
